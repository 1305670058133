<template>

<CustomDataSelector ref="customdateselector" >
</CustomDataSelector>

<div class="col-12" style="height:100px;">
</div> 
<br>
<hr>

<div style="margin:1%;" class="col-12">
<h5>開始年月日</h5>

<input v-model="settings.vstartname" class="form-control" placeholder="開始日の変数名 *半角英数" type="text"/> 
</div>

<div style="margin:1%;" class="col-12">

<h5>開始時間</h5>

<input v-model="settings.vstarthourname" class="form-control" placeholder="開始時間の変数名 *半角英数" type="text"/> 
</div>


<div style="margin:1%;" class="col-12">
<h5>終了日の有無 </h5> 

<input v-model="settings.needEnd" type="checkbox" /> <br> <small>* 設定しない場合は現在日時が終了日になります。 </small>
</div>

<div v-show="settings.needEnd" style="margin:1%;" class="col-12">

<h5>終了年月日</h5>

<input v-model="settings.vendname" class="form-control" placeholder="終了日の変数名 *半角英数" type="text"/> 

</div>

<div v-show="settings.needEnd" style="margin:1%;" class="col-12">
  <h5 style="display:inline-block;" >終了時間</h5>
<input v-model="settings.vendhourname" class="form-control" placeholder="終了時間の変数名 *半角英数" type="text"/> 
</div>


<div style="margin:1%;" class="col-12">
  <h5>センサID</h5>
<input v-model="settings.sensorids" class="form-control" placeholder="SensorIDs *コンマ区切り" type="text"/> 
</div>

<div style="margin:1%;" class="col-12">
<h5>テーブルの選択 </h5>

<div style="margin:3px;">

<label style="text-align:left; width:80px;">Aggregate </label> : 
<input v-model="settings.needAggregateData" type="checkbox" /> 
</div>

<div style="margin:3px;">

<label style="text-align:left; width:80px;">FirstData </label> : 
<input v-model="settings.needFirstData" type="checkbox" /> 
</div>

<div style="margin:3px;">

<label style="text-align:left; width:80px;">RawData </label> : 
<input v-model="settings.needRawData" type="checkbox" /> 
</div>
</div>

<div v-show="settings.needAggregateData|settings.needFirstData" style="margin:1%;" class="col-12">
<h5>時間粒度の選択 </h5>

<div style="margin:3px;">

<label style="text-align:left; width:70px;">1min </label> : 
<input v-model="settings.granularity1min" type="checkbox" /> 
</div>

<div style="margin:3px;">
<label style="text-align:left; width:70px;" >10min </label> : 
<input v-model="settings.granularity10min" type="checkbox" />  

</div>
<div style="margin:3px;">
<label style="text-align:left; width:70px;" >1hour </label> : 
<input v-model="settings.granularity1hour" type="checkbox" />  
</div>

</div>


<div style="margin:1%;" class="col-12">
<textarea v-model="settings.description" class="form-control" placeholder="説明" ></textarea>
</div>
<br>

<button class="btn btn-dark" v-on:click="update" > 更新 </button>


 <div style="height:200px;">
  
    
    </div>


</template>

<script>

import CustomDataSelector from "../../components/chart/CustomDateSelector.vue";

export default {
  name: 'CustomDateSelectorView',
  components:  { CustomDataSelector } ,
  data(){  return {


       times :[
            {  value : "1min" , name: "1分"   },
         //  {  value : "5min" , name: "5分"   },
            {  value : "10min" , name: "10分"   },
            {  value : "1hour" , name: "1時間"   }   
           ],
        settings : {
          
          starthour : "00" ,
          vstarthourname : "",
          endhour : "00" ,
          vendhourname : "",
vstartname : "",
needEnd : false,
vendname : "",
sensorids :"",

    needFirstData   :false ,
	  needAggregateData :false ,
	  needRawData :false, 
  
    granularity1min  : false ,
    granularity10min  : false , 
    granularity1hour : false , 
  
description: ""
}
        };
    },
  async created( ) {
        
      this.path = window.location.href;   
      const param = new URLSearchParams( encodeURI(window.location.search));
      const s = param.get('settings');
       
      if(s){
      
      try{ 
        const settings = window.decodeURIComponent(window.decodeURIComponent(window.decodeURIComponent(s)));
        this.settings = JSON.parse (settings);
      
      } catch (e) {
           try{ 
          
          const settings = window.decodeURIComponent(window.decodeURIComponent(s));
          this.settings = JSON.parse (settings);
      
          }catch (e){

            const settings = window.decodeURIComponent(s);
            this.settings = JSON.parse (settings);      
          }
       }
       }
      
       await this.update ();
      },
    methods: {
        
         
       async update (){ 
        
        const params = new URLSearchParams( window.location.search) // クエリパラメータを操作するためのオブジェクト
        
        params.set('settings',  encodeURIComponent (  JSON.stringify(this.settings)) ) // クエリパラメータに存在しない場合は追加、存在しているときは更新を行う
        // window.location.search = params.toString() ;
        
// const path =  window.location.href + "&q="+q ;

      window.history.pushState(
         {}, '', new URL( window.location.href ).pathname + "?settings="+ params.get('settings')
      ) ;  
          
    if ( false !== this.sq && window.parent !== window && !window.isCrossOrigin){
     
       const param = new URLSearchParams(window.parent.location.search);
       const id = param.get('id');

                             
            if (id){
       
                    window.parent.history.pushState( {}, '', new URL( window.parent.location.href).pathname + "?settings="+ params.get('settings') + '&id=' + id ) ;  
                  } else {
                  
                  window.parent.history.pushState( {}, '', new URL( window.parent.location.href).pathname + "?settings="+params.get('settings')   ) ;  
              }
              
          }
          
          
          if (this.$refs.customdateselector){
            
            await this.$refs.customdateselector.showData( JSON.parse(JSON.stringify(this.settings)) );
          }

        }
    }
}

</script>

<style>
main{
width: 100% !important ;
}

.container {
  width: 200px;
  margin: 30px;
}

.input {
  width: 100%;
  margin: 0px;
  padding-right: 5px;
}</style>